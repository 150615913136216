import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/2023/Childrensday/1.jpg';
import p2 from 'assests/Photos/SchoolProgram/2023/Childrensday/2.jpg';
import p3 from 'assests/Photos/SchoolProgram/2023/Childrensday/3.jpg';
import p4 from 'assests/Photos/SchoolProgram/2023/Childrensday/4.jpg';
import p5 from 'assests/Photos/SchoolProgram/2023/Childrensday/5.jpg';
import p6 from 'assests/Photos/SchoolProgram/2023/Childrensday/6.jpg';
import p7 from 'assests/Photos/SchoolProgram/2023/Childrensday/7.jpg';
import p8 from 'assests/Photos/SchoolProgram/2023/Childrensday/8.jpg';
import p9 from 'assests/Photos/SchoolProgram/2023/Childrensday/9.jpg';
import p10 from 'assests/Photos/SchoolProgram/2023/Childrensday/10.jpg';
import p11 from 'assests/Photos/SchoolProgram/2023/Childrensday/11.jpg';
import p12 from 'assests/Photos/SchoolProgram/2023/Childrensday/12.jpg';
import p13 from 'assests/Photos/SchoolProgram/2023/Childrensday/13.jpg';
import p14 from 'assests/Photos/SchoolProgram/2023/Childrensday/14.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const ChilderensDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const photos = [
          {
                    src: p2,
                    source: p2,
                    rows: 1,
                    cols: 2,
                },
                {
                    src: p1,
                    source: p1,
                    rows: 1,
                    cols: 1,
                },
                {
                    src: p3,
                    source: p3,
                    rows: 1,
                    cols: 1,
                },
      {
          src: p4,
          source: p4,
          rows: 1,
          cols: 2,
      },
      {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    },
    {
        src: p7,
        source: p7,
        rows: 1,
        cols: 2,
    },
{
src: p8,
source: p8,
rows: 1,
cols: 2,
},
{
    src: p9,
    source: p9,
    rows: 1,
    cols: 2,
},
{
    src: p10,
    source: p10,
    rows: 1,
    cols: 2,
},
{
    src: p11,
    source: p11,
    rows: 1,
    cols: 2,
},
{
src: p12,
source: p12,
rows: 1,
cols: 2,
},
{
    src: p13,
    source: p13,
    rows: 1,
    cols: 2,
},
{
src: p14,
source: p14,
rows: 1,
cols: 2,
},
     
      
      
    
     
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CHILDREN’S DAY  	
                                </Typography>
                                {/* <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary   Date: 12 OCTOBER 2023
                                </Typography> */}

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The air was filled with laughter, joy and the unmistakable sound of children's excitement as National Public School Yeshwanthpur celebrated Children's Day on Friday, 10 November 2023, to commemorate the birth anniversary of the first Prime Minister of Independent India, Pandit Jawaharlal Nehru, who was a great scholar and a statesman. He believed in nurturing the potential of the younger generation, envisioning a future shaped by their dreams and aspirations.  
                                    <br></br>
                                    The day kicked off with a vibrant assembly, where teachers, adorned in colourful attire, welcomed the students with infectious smiles. The school ground was transformed into a festive wonderland and was filled with an air of anticipation. The teachers surprised the students with a melodious Hindi Song, ‘Karthe Hai Aaj Hum Aapko Swagatham’, humourous skits, energetic power packed dance performances from a number of classic children's tunes to popular hits and incredible beat boxing that overjoyed the students beyond limit. The teachers' performances resonated with the young audience, fostering a sense of camaraderie and shared joy with loud cheers and thunderous applause echoed as teachers revealed their love towards the students with creativity and enthusiasm.    
                                    <br></br>
                                    This year’s celebrations were very special as it coincided with the glorious ten years of the school’s inception. To mark the occasion, the Principal Ms Sheeja Manoj released a badge to be preserved for posterity. The joyous faces of the children lit up as they received the prestigious badges and chocolates. The thoughtful gesture added an extra layer of happiness to an already magical day. The celebration continued with various interactive games and activities, ensuring that every child had the opportunity to participate and enjoy the festivities. The school created an immersive experience for the students, nurturing a sense of belonging and celebration.  
                    
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                                fontFamily={' normal'}
                            ><br />
                                “Every child is a star in their own right, shining with potential and curiosity.”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default ChilderensDay2023;